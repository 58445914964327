import React from 'react'
import { CFLink, CFView, Text } from 'components'

export default ({ address, phoneNumber, email }) => (
  <CFView column>
    {address && (
      <CFView row w="100%">
        <CFView w="9rem" mb="2rem">
          <Text content bold h4 white>
            Address
          </Text>
        </CFView>
        <CFLink href="https://goo.gl/maps/wSpgZGNyexq">
          <Text content bold h4 white>
            {address}
          </Text>
        </CFLink>
      </CFView>
    )}
    {phoneNumber && (
      <CFView row w="100%" mb="2rem">
        <CFView w="9rem">
          <Text content bold h4 white>
            Phone
          </Text>
        </CFView>
        <Text content bold h4 white>
          {phoneNumber}
        </Text>
      </CFView>
    )}
    {email && (
      <CFView row w="100%">
        <CFView w="9rem">
          <Text content bold h4 white>
            Email
          </Text>
        </CFView>
        <CFLink href={`mailto:${email}`}>
          <Text content bold h4 white>
            {email}
          </Text>
        </CFLink>
      </CFView>
    )}
  </CFView>
)
