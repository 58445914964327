import React from 'react'
import { logowhite } from 'images'
import { MEDIA_QUERY } from 'styles'
import { css } from 'emotion'

const LogoView = ({ className }) => (
  <img className={css(styles.logo, className)} src={logowhite} alt="Ryuu" />
)

const styles = {
  logo: css({
    cursor: 'pointer',
    height: '8rem',
    objectFit: 'contain',
    '&:hover': {
      transform: 'scale(1.03)',
    },
    [MEDIA_QUERY.SM]: {
      height: '6rem',
    },
  }),
}

export default LogoView
