import React, { Component } from 'react'
import { css } from 'emotion'
import { CFLink, CFView, Space, Text, PaintedButtonView } from 'components'
import HomeHeader from './HomeHeader'
import { mobileApp } from 'images'
import { MEDIA_QUERY } from 'styles'

class Hero extends Component {
  render() {
    const { OrderNowButtonElement } = this.props
    return (
      <div className={styles.container}>
        <HomeHeader />
        <div className={styles.content}>
          <div
            data-aos="fade-up"
            data-aos-easing="ease-in-out"
            className={styles.column}
          >
            <Text dafoe white center className={styles.header}>
              Authentic
            </Text>
            <Text heading2 white center className={styles.header2}>
              Japanese Cuisine
            </Text>
            <Space h2 />
            <Text white h4>
              Welcome to Ryuu Japanese Kitchen! We now support online ordering.
              Order now to collect points to redeem fantastic rewards!
            </Text>
            <Space height="4rem" />
            <CFView row>
              <div className={styles.orderButton}>{OrderNowButtonElement}</div>
              <div className={styles.downloadButton}>
                <CFLink href="http://onelink.to/4vntmc">
                  <PaintedButtonView label="DOWNLOAD APP" />
                </CFLink>
              </div>
            </CFView>
          </div>
          <div data-aos="fade-left" data-aos-easing="ease-in-out">
            <CFLink href="http://onelink.to/4vntmc">
              <img src={mobileApp} alt="Mobile App" className={styles.mobile} />
            </CFLink>
          </div>
        </div>
      </div>
    )
  }
  _handleOrderClick = () => {
    this.props.history.push('/menu')
  }
}
const styles = {
  container: css({
    height: '100vh',
    width: '100%',
    maxHeight: '64rem',
    [MEDIA_QUERY.MD]: {
      height: 'auto',
    },
  }),
  mobile: css({
    height: '60vh',
    maxHeight: '40rem',
    objectFit: 'contain',
    '&:hover': {
      transform: 'scale(1.03)',
    },
    [MEDIA_QUERY.MD]: {
      display: 'none',
    },
  }),
  column: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginRight: '6rem',
    width: '100%',
    maxWidth: '54rem',
    marginBottom: '2rem',
    [MEDIA_QUERY.MD]: {
      alignItems: 'center',
      textAlign: 'center',
      width: '90%',
      margin: 0,
    },
  }),
  content: css({
    width: '100%',
    marginTop: '4rem',
    display: 'flex',
    justifyContent: 'center',
  }),
  header: css({
    fontSize: 70,
    lineHeight: '6rem',
    [MEDIA_QUERY.MD]: {
      fontSize: 50,
      marginRight: '6rem',
    },
  }),
  header2: css({
    fontSize: 50,
    marginLeft: '12rem',
    [MEDIA_QUERY.MD]: {
      fontSize: 30,
      marginLeft: '6rem',
    },
  }),
  orderButton: css({
    marginRight: '2rem',
    [MEDIA_QUERY.MD]: {
      display: 'none',
    },
  }),
}

export default Hero
