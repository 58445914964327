import React from 'react'
import { Text, CFView } from 'components'
import { css } from 'emotion'

const LocationSelectNameView = ({ selectedLocationName }) => (
  <CFView className={styles.location}>
    <Text h2 din white>
      {selectedLocationName}
    </Text>
  </CFView>
)

const styles = {
  location: css({
    cursor: 'pointer',
    marginLeft: '1rem',
    marginRight: '1rem',
    marginTop: '1rem',
    borderRadius: '5rem',
    padding: '1rem 2rem',
    border: '2px solid white',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  }),
}

export default LocationSelectNameView
