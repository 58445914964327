import React, { Component } from 'react'
import { css } from 'emotion'
import { Space, Text } from 'components'
import {
  foodGallery1,
  foodGallery2,
  foodGallery3,
  foodGallery4,
  foodGallery8,
  foodGallery13,
  foodgallerybg,
  forkwhite,
  interiorGallery1,
  interiorGallery2,
  interiorGallery3,
  interiorGallery4,
  interiorGallery5,
  interiorGallery6,
  interiorgallerybg,
} from 'images'
import { MEDIA_QUERY } from 'styles'
import Slider from 'react-slick'

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  arrows: false,
  fade: true,
  autoplaySpeed: 2000,
}

class About extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.promo}>
            <Slider {...settings}>
              <img src={foodGallery1} alt="Food 1" className={styles.image} />
              <img src={foodGallery3} alt="Food 2" className={styles.image} />
              <img src={foodGallery8} alt="Food 2" className={styles.image} />
            </Slider>
          </div>
          <Space w1 />
          <div className={styles.foodbg}>
            <Text dafoe h1 white>
              Fantastic Food
            </Text>
            <img src={forkwhite} alt="White Fork" />
            <Text h4 white>
              Try our authentic Japanese home style food!
            </Text>
          </div>
          <Space w1 />
          <div className={styles.promo}>
            <Slider {...settings}>
              <img src={foodGallery2} alt="Food 2" className={styles.image} />
              <img src={foodGallery4} alt="Food 1" className={styles.image} />
              <img src={foodGallery13} alt="Food 2" className={styles.image} />
            </Slider>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.promo}>
            <Slider {...settings}>
              <img
                src={interiorGallery1}
                alt="Interior 1"
                className={styles.image}
              />
              <img
                src={interiorGallery3}
                alt="Interior 2"
                className={styles.image}
              />
              <img
                src={interiorGallery5}
                alt="Interior 1"
                className={styles.image}
              />
            </Slider>
          </div>
          <Space w1 />
          <div className={styles.interiorbg}>
            <Text dafoe h1 white>
              Amazing Space
            </Text>
            <img src={forkwhite} alt="White Fork" />
            <Text h4 white>
              Try our authentic Japanese home style food!
            </Text>
          </div>
          <Space w1 />
          <div className={styles.promo}>
            <Slider {...settings}>
              <img
                src={interiorGallery2}
                alt="Interior 2"
                className={styles.image}
              />
              <img
                src={interiorGallery4}
                alt="Interior 1"
                className={styles.image}
              />
              <img
                src={interiorGallery6}
                alt="Interior 1"
                className={styles.image}
              />
            </Slider>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  container: css({
    width: '100%',
    maxWidth: '100rem',
    margin: '4rem 0 4rem 0',
    [MEDIA_QUERY.LG]: {
      width: '100vw',
    },
  }),
  foodbg: css({
    width: '30%',
    background: `url(${foodgallerybg}) no-repeat`,
    backgroundSize: 'contain',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    textAlign: 'center',
    padding: '2rem',
    [MEDIA_QUERY.SM]: {
      margin: '2rem 0 1rem 1rem',
      width: '29rem',
      height: '29rem',
    },
  }),
  interiorbg: css({
    width: '30%',
    background: `url(${interiorgallerybg}) no-repeat`,
    backgroundSize: 'contain',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    textAlign: 'center',
    padding: '2rem',
    [MEDIA_QUERY.SM]: {
      margin: '2rem 0 1rem 1rem',
      width: '29rem',
      height: '29rem',
    },
  }),
  row: css({
    marginBottom: '1rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [MEDIA_QUERY.SM]: {
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '2rem',
    },
  }),
  promo: css({
    width: '30%',
    [MEDIA_QUERY.SM]: {
      width: '28rem',
      height: '28rem',
    },
  }),
  image: css({
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  }),
}

export default About
