import React, { Component } from 'react'
import { css } from 'emotion'
import { LocationSelect } from 'cf-web-app/components'
import { logo } from 'images'
import { NavbarView } from 'components'
import { MEDIA_QUERY } from 'styles'

class HomeHeader extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.location}>
          <img src={logo} alt="Ryuu Japanese Kitchen" className={styles.logo} />
          <LocationSelect />
        </div>
        <div className={styles.nav}>
          <NavbarView />
        </div>
      </div>
    )
  }
}

const styles = {
  container: css({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  nav: css({
    marginTop: '1.5rem',
    width: '90%',
    height: '6rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'solid',
    borderColor: 'white',
    borderWidth: '1px 0 1px 0',
  }),
  logo: css({
    height: '10rem',
    objectFit: 'contain',
  }),
  location: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
}

export default HomeHeader
