import React from 'react'
import { CFView, Text } from 'components'
import { css } from 'emotion'
import { pointsMeter, rewardbg, rewardDefault } from 'images'

const RewardsLoadedView = ({
  pointsMeterWidth,
  imageUrl,
  LeftArrowButtonElement,
  RightArrowButtonElement,
  points,
  maxPoints,
  requiredPoints,
  name,
  RedeemRewardButtonElement,
}) => (
  <div className={styles.rewardContainer}>
    <div className={styles.reward}>
      {imageUrl ? (
        <img className={styles.image} src={imageUrl} alt="Reward" />
      ) : (
        <img
          className={styles.rewardDefault}
          src={rewardDefault}
          alt="Reward"
        />
      )}
    </div>
    {LeftArrowButtonElement}
    {RightArrowButtonElement}
    <div className={styles.meter}>
      <div
        className={styles.progress}
        style={{
          width: `${pointsMeterWidth * 30.3}rem`,
          transition: 'width 2s',
        }}
      >
        <img src={pointsMeter} alt="Meter" />
      </div>
    </div>
    <div className={styles.pointsText}>
      <Text h3 din dark>
        {points} / {maxPoints}
      </Text>
    </div>
    <Text h2 din dark className={styles.requiredPoints}>
      {requiredPoints === 0 ? 'FREE' : `${requiredPoints} Points`}
    </Text>
    <CFView className={styles.name}>
      <Text h2 din white>
        {name}
      </Text>
    </CFView>
    <CFView className={styles.redeemButton}>{RedeemRewardButtonElement}</CFView>
  </div>
)

const styles = {
  rewardContainer: css({
    position: 'relative',
    height: '100%',
    width: '100%',
    background: `url(${rewardbg}) no-repeat`,
    backgroundSize: '100% 100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  reward: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  image: css({
    position: 'relative',
    bottom: '4rem',
    height: '12rem',
    width: '18.5rem',
    objectFit: 'cover',
  }),
  rewardDefault: css({
    position: 'relative',
    bottom: '4rem',
    objectFit: 'contain',
    width: '10rem',
  }),
  requiredPoints: css({
    position: 'absolute',
    top: '13rem',
  }),
  name: css({
    position: 'absolute',
    width: '100%',
    bottom: '10.8rem',
    textAlign: 'center',
  }),
  redeemButton: css({
    position: 'absolute',
    bottom: '1.4rem',
  }),
  meter: css({
    position: 'absolute',
    top: '4.05rem',
    left: '5.35rem',
  }),
  progress: css({
    overflow: 'hidden',
  }),
  pointsText: css({
    position: 'absolute',
    top: '7.2rem',
    right: '2rem',
  }),
}

export default RewardsLoadedView
