import React from 'react'
import { CFView, Text } from 'components'
import { css } from 'emotion'
import { logo } from 'images'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MEDIA_QUERY } from 'styles'
import { CFLink } from 'cf-web-app/components'

const LocationSelectModalView = ({ LocationSelectListElement }) => (
  <div className={styles.container}>
    <CFView column w="100%">
      <CFView column w="100%" center mb="1rem">
        <img src={logo} alt="Ryuu" width="300" />
      </CFView>
      <Text din h2 center>
        Please pick from the following locations:
      </Text>
      {LocationSelectListElement}
      <CFLink
        href="https://order.codefusion.tech/restaurants/f9s3rtVy0s6NUoDUSjBT/locations/oUmtEdK9tI78X3F2HnD6"
        target="_blank"
      >
        <CFView row alignCenter w="100%" mv="1rem" className={styles.button}>
          <FontAwesomeIcon icon="map-marker" className={styles.icon} />
          <CFView column ml="2rem" fill>
            <Text din h3 white>
              Poke By Ryuu
            </Text>
            <Text content h4 white>
              3358 Wesbrook Mall, Van
            </Text>
          </CFView>
          <FontAwesomeIcon icon="chevron-right" className={styles.icon} />
        </CFView>
      </CFLink>
    </CFView>
  </div>
)

const styles = {
  container: css({
    backgroundColor: '#232323',
    color: 'white',
    padding: '4rem',
    borderRadius: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45rem',
    [MEDIA_QUERY.SM]: {
      padding: '2rem',
      width: '100%',
    },
  }),
  button: css({
    borderRadius: '1rem',
    backgroundColor: 'rgba(151,151,151,.3)',
    padding: '1rem',
    cursor: 'pointer',
  }),
  icon: css({
    color: 'white',
    fontSize: '2.5rem',
  }),
}

export default LocationSelectModalView
