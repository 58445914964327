import React, { Component } from 'react'
import { css } from 'emotion'
import { Text } from 'components'
import { promo1, promo2, promo3, forkred, sushibg } from 'images'
import { MEDIA_QUERY } from 'styles'

class Promotions extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.promoContainer}>
          <div className={styles.promo}>
            <img src={promo1} alt="promo1" />
            <div className={styles.info}>
              <Text heading h4 bold dark>
                HAPPY HOUR
              </Text>
              <img src={forkred} alt="Fork" />
              <Text h5 dark>
                Everyday 3-6PM, enjoy select drinks and appys for $4 each!
              </Text>
            </div>
          </div>
          <div className={styles.promo}>
            <img src={promo2} alt="promo2" />
            <div className={styles.info}>
              <Text heading h4 bold dark>
                SPECIALS
              </Text>
              <img src={forkred} alt="Fork" />
              <Text h5 dark>
                Everyday 3-6PM, enjoy select drinks and appys for $4 each!
              </Text>
            </div>
          </div>
          <div className={styles.promo}>
            <img src={promo3} alt="promo3" />
            <div className={styles.info}>
              <Text heading h4 bold dark>
                ANNOUNCEMENT
              </Text>
              <img src={forkred} alt="Fork" />
              <Text h5 dark>
                Everyday 3-6PM, enjoy select drinks and appys for $4 each!
              </Text>
            </div>
          </div>
        </div>
        <div className={styles.sushi} />
      </div>
    )
  }
}

const styles = {
  container: css({
    width: '100%',
    height: '50rem',
    padding: '0 4rem 0 4rem',
    marginTop: '4rem',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    [MEDIA_QUERY.LG]: {
      overflowX: 'auto',
      width: '100vw',
      padding: 0,
      height: '42rem',
    },
  }),
  promoContainer: css({
    width: '100%',
    maxWidth: '90rem',
    display: 'flex',
    justifyContent: 'space-between',
  }),
  promo: css({
    height: '40rem',
    position: 'relative',
    zIndex: 2,
  }),
  info: css({
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    height: '50%',
    width: '100%',
    flexDirection: 'column',
    padding: '4rem 2rem 2rem 2rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    textAlign: 'center',
    zIndex: 3,
  }),
  image: css({
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    objectFit: 'contain',
    position: 'absolute',
    zIndex: 1,
  }),
  sushi: css({
    height: '30rem',
    width: '100%',
    maxWidth: '120rem',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    background: `url(${sushibg}) no-repeat`,
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    [MEDIA_QUERY.MD]: {
      display: 'none',
    },
  }),
}

export default Promotions
