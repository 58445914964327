import React from 'react'
import { css } from 'emotion'
import PageFooter from './PageFooter'
import Hero from './Hero'
import Promotions from './Promotions'
import About from './About'
import Contact from './Contact'
import { Space } from 'components'
import { woodbg } from 'images'

class HomeView extends React.Component {
  render() {
    const {
      HoursElement,
      ContactUsElement,
      OrderNowButtonElement,
      LogoElement,
    } = this.props
    return (
      <section className={styles.container}>
        <div className={styles.innerContainer}>
          <Hero OrderNowButtonElement={OrderNowButtonElement} />
          <Space h2 />
          <Promotions />
          <Space h2 />
          <About />
          <Space h2 />
          <Contact
            HoursElement={HoursElement}
            LogoElement={LogoElement}
            ContactUsElement={ContactUsElement}
          />
          <PageFooter />
        </div>
      </section>
    )
  }
}

const styles = {
  container: css({
    display: 'flex',
    justifyContent: 'center',
    background: `url(${woodbg}) no-repeat`,
    backgroundSize: '100% 100%',
    zIndex: -99,
  }),
  innerContainer: css({
    maxWidth: '110rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,.6)',
  }),
}

export default HomeView
