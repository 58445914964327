import React, { Component } from 'react'
import { css } from 'emotion'
import { CFLink, CFView, Space, Text } from 'components'
import { appStore, googlePlay, locationMap } from 'images'
import { MEDIA_QUERY } from 'styles'

class Contact extends Component {
  render() {
    const { HoursElement, ContactUsElement } = this.props
    return (
      <CFView column>
        <CFLink href="https://goo.gl/maps/jtykuC8HMJ72">
          <img
            src={locationMap}
            alt="Location"
            className={styles.locationMap}
          />
        </CFLink>
        <div className={styles.container}>
          <div
            className={styles.column}
          >
            <Text heading h2 red bold>
              CONTACT US
            </Text>
            <Space h2 />
            {ContactUsElement}
            <Space height="3rem" />
            <Text heading h2 red bold>
              DOWNLOAD APP
            </Text>
            <Space h2 />
            <CFView row>
              <div className={styles.buttonBar}>
                <CFLink href="https://itunes.apple.com/us/app/ryuu-japanese-kitchen/id1458306384">
                  <img
                    className={styles.mobile}
                    src={appStore}
                    alt="App Store"
                  />
                </CFLink>
                <Space w2 />
                <CFLink href="https://play.google.com/store/apps/details?id=tech.codefusion.ryuu">
                  <img
                    className={styles.mobile}
                    src={googlePlay}
                    alt="Goodle Play"
                  />
                </CFLink>
              </div>
            </CFView>
          </div>
          <Space h2 />
          <div
            className={styles.column}
          >
            <Text heading h2 red bold>
              BUSINESS HOURS
            </Text>
            <Space h2 />
            <div className={styles.hours}>{HoursElement}</div>
          </div>
        </div>
      </CFView>
    )
  }
}

const styles = {
  container: css({
    width: '100%',
    padding: '4rem',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    backgroundColor: '#191B20',
    boxShadow: '0 .4rem .8rem rgba(0,0,0,0.8)',
    [MEDIA_QUERY.MD]: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: '2rem',
    },
  }),
  locationMap: css({
    width: '100%',
  }),
  hours: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '35rem',
  }),
  mobile: css({
    height: '4rem',
    objectFit: 'contain',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.03)',
    },
  }),
  column: css({
    display: 'flex',
    flexDirection: 'column',
    [MEDIA_QUERY.MD]: {
      alignItems: 'center',
    },
  }),
  buttonBar: css({
    display: 'flex',
  }),
}

export default Contact
