export * from 'cf-core/src/images'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/hatopopo/app-store.png'
export const background =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/hatopopo/background.jpg'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/sushihowe/cflogo.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/hatopopo/cftitle.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/hatopopo/facebook.png'
export const footerbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/hatopopo/footerbg.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/hatopopo/google-play.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/hatopopo/instagram.png'
export const twitter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/hatopopo/twitter.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/hatopopo/user.png'
export const woodbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/woodbg.png'
export const sushibg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/sushibg.png'
export const locationMap =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/locationMap.png'
export const logo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/logo.png'
export const mobileApp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1550314046/ryuu/mobileApp.png'
export const forkred =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/forkred.png'
export const forkwhite =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/forkwhite.png'
export const promo1 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/promo1.png'
export const promo2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/promo2.png'
export const promo3 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/promo3.png'
export const foodGallery1 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/foodGallery1.png'
export const foodGallery2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/foodGallery2.png'
export const foodGallery3 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/foodGallery3.png'
export const foodGallery4 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/foodGallery4.png'
export const foodGallery5 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/foodGallery5.png'
export const foodGallery6 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/foodGallery6.png'
export const foodGallery7 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/foodGallery7.png'
export const foodGallery8 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/foodGallery8.png'
export const foodGallery9 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/foodGallery9.png'
export const foodGallery10 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/foodGallery10.png'
export const foodGallery11 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/foodGallery11.png'
export const foodGallery12 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/foodGallery12.png'
export const foodGallery13 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/foodGallery13.png'
export const foodGallery14 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/foodGallery14.png'
export const foodGallery15 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/foodGallery15.png'
export const foodGallery16 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/foodGallery16.png'
export const foodGallery17 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/foodGallery17.png'
export const foodGallery18 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/foodGallery18.png'
export const foodgallerybg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/foodgallerybg.png'
export const interiorGallery1 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/interiorGallery1.png'
export const interiorGallery2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/interiorGallery2.png'
export const interiorGallery3 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/interiorGallery3.png'
export const interiorGallery4 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/interiorGallery4.png'
export const interiorGallery5 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/interiorGallery5.png'
export const interiorGallery6 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/interiorGallery6.png'
export const interiorGallery7 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/interiorGallery7.png'
export const interiorGallery8 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/interiorGallery8.png'
export const interiorgallerybg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/interiorgallerybg.png'
export const logowhite =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/logowhite.png'
export const smallLogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/smallLogo.png'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/rewardbg.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/rewardArrowRight.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/rewardArrowLeft.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/redeemButton.png'
export const rewardGirl =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/rewardGirl.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/pointsMeter.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ryuu/rewardDefault.png'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
